import "@fontsource/archivo";
import "./highlight.css";

import React from "react";
import {
  createTheme,
  CssBaseline,
  ThemeProvider,
  Toolbar,
} from "@mui/material";
import TopBar from "./TopBar";
import { Helmet } from "react-helmet";

const Layout: React.FC<{ hideTopBar?: boolean }> = ({
  children,
  hideTopBar,
}) => {
  const theme = createTheme({
    palette: {
      primary: {
        light: "#ff5dbe",
        main: "#ff008e",
        dark: "#c60061",
        contrastText: "#fff",
      },
      secondary: {
        light: "#9255c8",
        main: "#612897",
        dark: "#300068",
        contrastText: "#fff",
      },
      error: {
        light: "#ff6036",
        main: "#ff1700",
        dark: "#c20000",
        contrastText: "#000",
      },
      warning: {
        light: "#ffff55",
        main: "#ffe400",
        dark: "#c7b200",
        contrastText: "#000",
      },
      success: {
        light: "#71ff56",
        main: "#06ff00",
        dark: "#00ca00",
        contrastText: "#000",
      },
      info: {
        light: "#8fbcff",
        main: "#548cff",
        dark: "#005fcb",
        contrastText: "#000",
      },
    },
    shape: {
      borderRadius: 2,
    },
    typography: {
      fontFamily: "'Archivo', 'Helvetica', 'Arial', sans-serif;",
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Helmet>
        <title>Radoku | Tunnels to your local servers</title>
        <meta
          name="description"
          content={
            "Tunnels with public internet addresses to your local servers"
          }
        />
      </Helmet>
      {!hideTopBar && <TopBar />}
      {!hideTopBar && <Toolbar />}
      {children}
    </ThemeProvider>
  );
};

export default Layout;
