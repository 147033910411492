import React from "react";
import { AppBar, Link, Toolbar, Typography, Button, Box } from "@mui/material";

const TopBar = () => {
  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: (theme) => theme.palette.background.default,
        color: (theme) => theme.palette.text.primary,
        borderBottomWidth: 5,
        borderBottomColor: (theme) => theme.palette.secondary.main,
        borderBottomStyle: "solid",
      }}
    >
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ mr: 2 }}>
          <Link href="https://radoku.com" underline="none" color="primary">
            Radoku
          </Link>
        </Typography>
        <Button color="inherit" href="/documentation">
          Documentation
        </Button>
        <Box sx={{ flexGrow: 1 }}></Box>
        <Button color="inherit" href="https://iris.radoku.com">
          Dashboard
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
